import { merge } from "object-mapper";
import { IDeliveryPhoto } from "../types/types";
import { IModelMapper } from "./IModelMapper";
import { IDeliveryPhotoResponse } from "../types/responses";

export class DeliveryPhotoModelMapper implements IModelMapper<IDeliveryPhotoResponse, IDeliveryPhoto> {
  private map = {
    id: "id",
    original_file_name: "originalFileName",
    mime_type: "mimeType",
    file_size_bytes: "fileSizeBytes",
    saved_file_name: "savedFileName",
    delivery: "delivery",
    created_by: "createdBy",
    updated_by: "updatedBy",
    created_at: "createdAt",
    updated_at: "updatedAt",
  };

  toModel(json: IDeliveryPhotoResponse): IDeliveryPhoto {
    return merge(json, this.map) as IDeliveryPhoto;
  }
}
