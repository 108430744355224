import React, { ReactElement } from "react";
import { Center, useColorModeValue, Text, Link } from "@chakra-ui/react";

export const Footer = (): ReactElement => {
  return (
    <Center
      marginTop={"20px"}
      minH={"16"}
      bg={useColorModeValue("red.500", "gray.900")}
      w={"100%"}
      zIndex={1}
    >
      <Text fontSize={"xs"} color={"whiteAlpha.900"}>
        Created by{" "}
        <Link href={"https://www.linkedin.com/in/saifulss"} isExternal>
          SSS
        </Link>
        ,{" "}
        <Link href={"https://www.linkedin.com/in/mdmohsinismail"} isExternal>
          Mohsin
        </Link>{" "}
        and the folks at BravoZuluSG
      </Text>
    </Center>
  );
};
