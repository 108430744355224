import React, { ReactElement, useState } from "react";
import { Stack, } from "@chakra-ui/react";
import { IEvent } from "../../types/types";
import { EventDropdownMenu } from "../../components/EventDropdownMenu";
import { DeliveriesDashboard } from "../../components/DeliveriesDashboard/DeliveriesDashboard";

export const DashboardView = (): ReactElement => {
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);

  return (
    <Stack spacing={4} mb={8}>
      <EventDropdownMenu selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent} />
      {selectedEvent && <DeliveriesDashboard selectedEvent={selectedEvent} />}
    </Stack>
  );
};
