import { Stack } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { IDelivery } from "../types/types";
import { DeliveryCard } from "./DeliveryCard";

interface IOwnProps {
  deliveries: IDelivery[];
}

export const DeliveriesAsCards = ({ deliveries }: IOwnProps): ReactElement => {
  const deliveryCards = deliveries!!.map((delivery: any) => (
    <DeliveryCard key={delivery.id} delivery={delivery} />
  ));

  return <Stack spacing={8}>{deliveryCards}</Stack>;
};
