import React, { ReactElement } from "react";
import { Text } from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { EDeliveryStatus, IDeliveryPhoto } from "../types/types";

interface IOwnProps {
  deliveryStatus: EDeliveryStatus;
  deliveryPhoto: IDeliveryPhoto;
}

export const PhotoSymbol = ({
  deliveryStatus,
  deliveryPhoto,
}: IOwnProps): ReactElement => {
  let statusContent;
  if (deliveryStatus === EDeliveryStatus.DELIVERED && deliveryPhoto)
    statusContent = <CheckIcon color={"green.500"} />;
  else if (deliveryStatus === EDeliveryStatus.DELIVERED && !deliveryPhoto)
    statusContent = <CloseIcon color={"red.500"} />;
  else statusContent = <Text color={"gray.500"}>-</Text>;

  return statusContent;
};
