interface RouteConstant {
  path: string;
  title: string;
}

const HOME_ROUTE: RouteConstant = {
  path: "/",
  title: "Home",
};
const FAQS_ROUTE: RouteConstant = {
  path: "/faqs",
  title: "FAQs",
};
const DELIVERIES_ROUTE: RouteConstant = {
  path: "/deliveries",
  title: "My Deliveries",
};
const DASHBOARD_ROUTE: RouteConstant = {
  path: "/dashboard",
  title: "Dashboard",
};
const ADMIN_DASHBOARD_ROUTE: RouteConstant = {
  path: "/admin/dashboard",
  title: "Admin Dashboard",
};
const LOGIN_ROUTE: RouteConstant = {
  path: "/login",
  title: "Sign in",
};
const LOGOUT_ROUTE: RouteConstant = {
  path: "/logout",
  title: "Sign out",
};

export const RouteConstants = {
  HOME_ROUTE,
  FAQS_ROUTE,
  DELIVERIES_ROUTE,
  DASHBOARD_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
};
