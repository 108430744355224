import React, { ReactElement } from "react";
import { ExternalLink } from "./ExternalLink";

interface IOwnProps {
  blockNumber: string;
  streetName: string;
  unitNumber: string;
  postalCode?: string;
  googleMapsLink: string;
}

export const AddressWithLink = (props: IOwnProps): ReactElement => {
  const { blockNumber, streetName, unitNumber, postalCode, googleMapsLink } =
    props;

  let text = `${blockNumber} ${streetName}, #${unitNumber}`;
  if (postalCode) text += `, ${postalCode}`;

  return <ExternalLink text={text} href={googleMapsLink} />;
};
