import React, { ReactElement } from "react";
import styles from "./MenuItemLink.module.scss";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router";
import { MenuItem, Text } from "@chakra-ui/react";

interface IOwnProps {
  label: string;
  routePath: string;
}

export const MenuItemLink = ({ label, routePath }: IOwnProps): ReactElement => {
  const history = useHistory();
  const pathname = history.location.pathname;

  return (
    <MenuItem _focus={{ bg: "transparent" }}>
      <RouterLink className={styles.menuItemLink} to={routePath}>
        <Text
          as={"span"}
          className={pathname === routePath ? styles.highlight : undefined}
        >
          {label}
        </Text>
      </RouterLink>
    </MenuItem>
  );
};
