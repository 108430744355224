import React, { ReactElement, useState } from "react";
import { Stack } from "@chakra-ui/react";
import { EventDropdownMenu } from "../components/EventDropdownMenu";
import { IEvent } from "../types/types";
import { Deliveries } from "../components/Deliveries";

export const DeliveriesView = (): ReactElement => {
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);

  return (
    <Stack spacing={4} mb={8}>
      <EventDropdownMenu selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent} />
      {selectedEvent && <Deliveries selectedEvent={selectedEvent} />}
    </Stack>
  );
};
