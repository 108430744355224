import React, { ReactElement, useEffect } from "react";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { IEvent } from "../types/types";
import { useGetEventsMineQuery } from "../redux/strapiApiSlice";

interface IOwnProps {
  selectedEvent: IEvent | null;
  setSelectedEvent: (event: IEvent | null) => void;
}

export const EventDropdownMenu = (props: IOwnProps): ReactElement => {
  const { data: events, isSuccess } = useGetEventsMineQuery(null);
  const { selectedEvent, setSelectedEvent } = props

  useEffect(() => {
    if (isSuccess && events && events.length === 1) {
      setSelectedEvent(events[0]);
    } else {
      setSelectedEvent(null);
    }
  }, [events, isSuccess, setSelectedEvent]);

  if (events === undefined || events.length === 0) {
    return <></>;
  }

  const menuItems = events.map((event) => (
    <MenuItem key={event.id} onClick={() => setSelectedEvent(event)}>
      {event.title}
    </MenuItem>
  ));

  const buttonLabel = selectedEvent ? selectedEvent.title : (events.length === 1 ? events[0].title : "Select Event");

  return (
    <Menu>
      <MenuButton as={Button} colorScheme="blue">
        {buttonLabel}
      </MenuButton>
      <MenuList>
        {menuItems}
      </MenuList>
    </Menu>
  );
};
