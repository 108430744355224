import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../types/types";
import { ILoginSuccessResponse } from "../types/responses";
import { UserModelMapper } from "../modelMappers/UserModelMapper";
import { RootState } from "./store";

interface IInitialState {
  jwt?: string;
  user?: IUser;
}

const initialState: IInitialState = {
  jwt: undefined,
  user: undefined,
};

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthDetails: (
      state,
      { payload: { jwt, user } }: PayloadAction<ILoginSuccessResponse>
    ) => {
      state.jwt = jwt;
      state.user = new UserModelMapper().toModel(user);
    },
    clearAuthDetails: (state) => {
      state.jwt = undefined;
      state.user = undefined;
      return state;
    },
  },
});

export const { setAuthDetails, clearAuthDetails } = authSlice.actions;

export const selectUser = (state: RootState) => state.authReducer.user;
export const selectJwt = (state: RootState) => state.authReducer.jwt;

export const authReducer = authSlice.reducer;
