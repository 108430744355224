import { Moment } from "moment";

export type IRoleName = "Public" | "Authenticated" | "Organisation Admin" | "System Admin";

export interface IUser {
  id: number;
  email: string;
  username: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  role: IRole;
  mobileNumber: string;
  fullName: string;
  createdAt: Moment;
  updatedAt: Moment;
}

export interface IRole {
  id: number;
  name: string;
  description: string;
  type: string;
}

export interface IDelivery {
  id: number;
  beneficiary: IBeneficiary | Pick<IBeneficiary, "id" | "uniqueIdentifier">;
  event: IEvent;
  driver: IUser;
  caseNotes?: string;
  driverRemarks?: string;
  status: EDeliveryStatus;
  deliveryPhoto: IDeliveryPhoto;
}

export interface IBeneficiary {
  id: number;
  uniqueIdentifier: string;
  recipientName: string;
  mobileNumber: string;
  blockNumber: string;
  unitNumber: string;
  streetName: string;
  area: string;
  postalCode: string;
  lat: string;
  lng: string;
  googleMapsLink: string;
  createdBy?: number;
  updatedBy?: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IEvent {
  id: number;
  title: string;
  eventDate: Moment;
  organisation: IOrganisation;
  createdBy: number;
  updatedBy: number;
  createdAt: Moment;
  updatedAt: Moment;
}

export interface IOrganisation {
  id: number;
  name: string;
  createdBy: number;
  updatedBy: number;
  createdAt: Moment;
  updatedAt: Moment;
}

export interface IDeliveryPhoto {
  id: number;
  originalFileName: string;
  mimeType: string;
  fileSizeBytes: number;
  savedFileName: string;
  delivery: number;
  created_by?: number;
  updated_by?: number;
  created_at: Moment;
  updated_at: Moment;
}

export enum EDeliveryStatus {
  NOT_STARTED = "NOT_STARTED",
  DELIVERED = "DELIVERED",
  DELIVERY_FAILED = "DELIVERY_FAILED",
}