import React, { ReactElement, useState } from "react";
import { Heading, Stack, Switch, Text } from "@chakra-ui/react";
import { IEvent } from "../types/types";
import { useGetDeliveriesQuery } from "../redux/strapiApiSlice";
import { DeliveriesAsTable } from "./DeliveriesAsTable";
import { DeliveriesAsCards } from "./DeliveriesAsCards";
import { AppCircularProgress } from "./AppCircularProgress";

interface IOwnProps {
  selectedEvent: IEvent;
}

export const Deliveries = (props: IOwnProps): ReactElement => {
  const { selectedEvent } = props;
  const { data: deliveries, isSuccess } = useGetDeliveriesQuery({
    eventId: selectedEvent.id,
  });
  const [viewAsTable, setViewAsTable] = useState(false);

  if (!isSuccess) {
    return <AppCircularProgress text={"Loading..."} />;
  }

  if (!deliveries) {
    return <Text>No deliveries found for this event.</Text>;
  }

  const content = viewAsTable ? (
    <DeliveriesAsTable deliveries={deliveries} />
  ) : (
    <DeliveriesAsCards deliveries={deliveries} />
  );

  return (
    <>
      {selectedEvent && (
        <>
          <Heading fontSize={"xl"}>
            My Deliveries: {deliveries!!.length} total
          </Heading>
          <Stack direction={"row"} alignItems={"center"} spacing={2} fontSize={"xs"}>
            <Text as={"span"}>View as:</Text>
            <Text as={"span"}>cards</Text>
            <Switch
              isChecked={viewAsTable}
              onChange={() => setViewAsTable(!viewAsTable)}
            />
            <Text as={"span"}>table</Text>
          </Stack>
          {content}
        </>
      )}
    </>
  )
};
