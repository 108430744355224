import React, { ReactElement } from "react";
import { Heading, ListItem, Stack, UnorderedList } from "@chakra-ui/react";

interface IOwnProps {
  serialNumber: number;
  questionText: string;
  textLines: string[];
}

export const FaqItem = ({
  questionText,
  textLines,
  serialNumber,
}: IOwnProps): ReactElement => {
  const listItems = textLines.map((textLine) => (
    <ListItem key={textLine.substring(0, 20)}>{textLine}</ListItem>
  ));

  return (
    <Stack>
      <Heading fontSize={"md"}>
        {serialNumber}) {questionText}
      </Heading>
      <UnorderedList pl={4}>{listItems}</UnorderedList>
    </Stack>
  );
};
