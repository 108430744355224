import { merge } from "object-mapper";
import { IEvent } from "../types/types";
import { IModelMapper } from "./IModelMapper";
import { IEventResponse } from "../types/responses";

export class EventModelMapper implements IModelMapper<IEventResponse, IEvent> {
  private map = {
    id: "id",
    title: "title",
    event_date: "eventDate",
    organisation: "organisation",
    created_by: "createdBy",
    updated_by: "updatedBy",
    created_at: "createdAt",
    updated_at: "updatedAt",
  };

  toModel(json: IEventResponse): IEvent {
    return merge(json, this.map) as IEvent;
  }
}
