import React, { ReactElement } from "react";
import { IDeliveryPhoto } from "../types/types";
import { Text, Center, Image, Spinner } from "@chakra-ui/react";
import { useGetDeliveryPhotoQuery } from "../redux/strapiApiSlice";

interface IOwnProps {
  deliveryPhoto: IDeliveryPhoto;
}

export const DeliveryPhoto = ({ deliveryPhoto }: IOwnProps): ReactElement => {
  const {
    data: photoAsBlob,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useGetDeliveryPhotoQuery({
    id: deliveryPhoto.id,
  });

  return (
    <Center minH={"200px"}>
      {isLoading || isFetching ? <Spinner /> : null}
      {isSuccess ? <Image src={photoAsBlob} alt={"Delivery Photo"} /> : null}
      {isError ? <Text>Something went wrong.</Text> : null}
    </Center>
  );
};
