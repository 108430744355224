import { Container, Stack } from "@chakra-ui/react";
import React, { ReactElement, useEffect } from "react";
import { Navbar } from "../Navbar/Navbar";
import { Footer } from "../Footer";
import { useAppSelector } from "../../redux/hooks";
import { Redirect, useHistory } from "react-router";
import { RouteConstants } from "../../constants/RouteConstants";
import { selectJwt, selectUser } from "../../redux/authSlice";
import { IRoleName } from "../../types/types";
import { ErrorToast } from "../ErrorToast";

interface IOwnProps {
  viewComponent: ReactElement;
  roleNames?: IRoleName[];
}

export const BaseView = ({ viewComponent, roleNames }: IOwnProps) => {
  const jwt = useAppSelector(selectJwt);
  const user = useAppSelector(selectUser);
  const history = useHistory();

  const isLoggedIn = jwt && user;
  const isLoginRoute =
    history.location.pathname === RouteConstants.LOGIN_ROUTE.path;
  const isLogoutRoute =
    history.location.pathname === RouteConstants.LOGOUT_ROUTE.path;

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const pathname = history.location.pathname;
    let title: string = "Delivery App";
    let routeConstant: keyof typeof RouteConstants;
    for (routeConstant in RouteConstants) {
      if (RouteConstants[routeConstant].path === pathname) {
        title = RouteConstants[routeConstant].title;
      }
    }
    window.document.title = title;
  });

  if (!isLoggedIn && !isLoginRoute && !isLogoutRoute) {
    console.log("Not logged in. Redirecting...");
    return <Redirect to={RouteConstants.LOGIN_ROUTE.path} />;
  }

  if (roleNames) {
    if (user && user.role && !(roleNames as string[]).includes(user.role.name)) {
      console.log("Not authorized. Redirecting...");
      return <Redirect to={RouteConstants.LOGIN_ROUTE.path} />;
    }
  }

  return (
    <Stack alignItems={"center"}>
      <ErrorToast />
      <Navbar />
      <Container mt={"24px"} overflow={"hidden"} minH={"100vh"}>
        {viewComponent}
      </Container>
      <Footer />
    </Stack>
  );
};

BaseView.defaultProps = {
  roleName: "Authenticated",
};
