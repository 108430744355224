import React, { ReactElement } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";
import { RouteConstants } from "../../constants/RouteConstants";
import { Link as RouterLink } from "react-router-dom";
import logo from "../../../../delivery-app-react/src/assets/social-good-logo.svg";
import { MenuItemLink } from "../MenuItemLink/MenuItemLink";
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/authSlice";

export const Navbar = (): ReactElement => {
  const user = useAppSelector(selectUser);
  const fullName = user?.fullName;

  const driverNavItems = (
    <>
      <MenuItemLink
        label={RouteConstants.DASHBOARD_ROUTE.title}
        routePath={RouteConstants.DASHBOARD_ROUTE.path}
      />
      <MenuItemLink
        label={RouteConstants.DELIVERIES_ROUTE.title}
        routePath={RouteConstants.DELIVERIES_ROUTE.path}
      />
      <MenuItemLink
        label={RouteConstants.FAQS_ROUTE.title}
        routePath={RouteConstants.FAQS_ROUTE.path}
      />
    </>
  );

  let organisationAdminNavItems = null;
  if (
    user &&
    (user.role.name === "Organisation Admin" ||
      user.role.name === "System Admin")
  ) {
    organisationAdminNavItems = (
      <>
        <MenuItemLink
          label={RouteConstants.ADMIN_DASHBOARD_ROUTE.title}
          routePath={RouteConstants.ADMIN_DASHBOARD_ROUTE.path}
        />
      </>
    );
  }

  return (
    <>
      <Box
        pos={"fixed"}
        w={"100%"}
        zIndex={1}
        bg={useColorModeValue("red.500", "gray.900")}
        px={4}
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <HStack spacing={8} alignItems={"center"}>
            <Box>
              <RouterLink to={RouteConstants.HOME_ROUTE.path}>
                <Image borderRadius="full" boxSize="32px" src={logo} />
              </RouterLink>
            </Box>
            <Box color={"whiteAlpha.900"} fontWeight={"semibold"}>
              Social Good Delivery App
            </Box>
          </HStack>
          <Flex alignItems={"center"}>
            {fullName && (
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    size={"sm"}
                    name={fullName}
                    bg={"whiteAlpha.900"}
                    color={"red.500"}
                  />
                </MenuButton>
                <MenuList>
                  {driverNavItems}
                  <MenuDivider />
                  {organisationAdminNavItems}
                  {organisationAdminNavItems && <MenuDivider />}
                  <MenuItemLink
                    label={RouteConstants.LOGOUT_ROUTE.title}
                    routePath={RouteConstants.LOGOUT_ROUTE.path}
                  />
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Flex>
      </Box>
      <Box minH={"64px"} />
    </>
  );
};
