import React, { ReactElement, useState } from "react";
import { Button, Heading, Spinner, Stack } from "@chakra-ui/react";
import { ApiConstants } from "../constants/ApiConstants";
import fileDownload from "js-file-download";
import axios from "axios";

export const AdminDashboardView = (): ReactElement => {
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <Stack spacing={4} mb={8}>
      <Heading fontSize={"xl"}>Admin Dashboard</Heading>
      <Button
        disabled={isDownloading}
        onClick={async () => {
          setIsDownloading(true);
          const response = await axios.get(
            `${ApiConstants.BASE_URL}/organisation-admin/deliveries/report`,
            { responseType: "blob" }
          );

          const contentDispositionHeader = response.headers[
            "content-disposition"
          ] as string;
          const regexMatches =
            contentDispositionHeader.match(/"[A-z0-9-.\s]*"/);
          const fileNameWithExtension = regexMatches
            ? regexMatches[0].replaceAll('"', "")
            : "Event Report.csv";
          fileDownload(response.data, fileNameWithExtension);
          setIsDownloading(false);
        }}
      >
        {isDownloading ? <Spinner /> : "Download Event Report"}
      </Button>
    </Stack>
  );
};
