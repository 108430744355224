import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { IDelivery } from "../types/types";
import { StatusSymbol } from "./StatusSymbol";
import { TruncatedText } from "./TruncatedText";
import { PhotoSymbol } from "./PhotoSymbol";

interface IOwnProps {
  deliveries: IDelivery[];
}

export const RedactedDeliveriesAsTable = ({
  deliveries,
}: IOwnProps): ReactElement => {
  const content = deliveries.map((delivery) => {
    const {
      status,
      beneficiary: { uniqueIdentifier },
      driver: { fullName },
    } = delivery;

    return (
      <Tr key={uniqueIdentifier}>
        <Td fontSize={"x-small"}>{uniqueIdentifier}</Td>
        <Td fontSize={"x-small"}>
          <TruncatedText text={fullName} limit={20} />
        </Td>
        <Td textAlign={"center"}>
          <StatusSymbol status={status} />
        </Td>
        <Td textAlign={"center"}>
          <PhotoSymbol
            deliveryStatus={delivery.status}
            deliveryPhoto={delivery.deliveryPhoto}
          />
        </Td>
      </Tr>
    );
  });

  return (
    <Box mb={8} boxShadow={"md"} rounded={"md"} w={"full"}>
      <Table size="sm" variant={"simple"}>
        <Thead>
          <Tr>
            <Th color={"gray.500"} fontSize={"xx-small"}>Beneficiary</Th>
            <Th color={"gray.500"} fontSize={"xx-small"}>Driver</Th>
            <Th color={"gray.500"} fontSize={"xx-small"}>Status</Th>
            <Th color={"gray.500"} fontSize={"xx-small"}>Photo</Th>
          </Tr>
        </Thead>

        <Tbody>{content}</Tbody>
      </Table>
    </Box>
  );
};
