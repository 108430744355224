import React, { ChangeEvent, ReactElement, useRef } from "react";
import { ImageCompressor } from "../service/ImageCompressor";
import { Button, HStack } from "@chakra-ui/react";

const targetSizeInMegabytes = 1

interface IOwnProps {
  setPhotoDataUrl: (photoDataUrl: string) => void;
}

export const UploadPhoto = ({ setPhotoDataUrl }: IOwnProps): ReactElement => {

  const inputRef = useRef<HTMLInputElement>(null);

  const handleCompressedUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const selectedFile = e.target.files[0];

    if (!selectedFile) return;



    const originalSizeInMegabytes = selectedFile.size / 1000000;
    const targetQuality = targetSizeInMegabytes / originalSizeInMegabytes;

    if (targetQuality > 1) {
      console.log(`No compression needed for type ${selectedFile.type} of size ${originalSizeInMegabytes}MB`);
      convertAndReturn(selectedFile, setPhotoDataUrl);
    } else {
      const imageCompressor = new ImageCompressor(targetSizeInMegabytes);
      let compressedImage = await imageCompressor.compress(selectedFile);
      const compressedSizeInMegabytes = compressedImage.size / 1000000;
      const compressionRatio = Math.round(compressedSizeInMegabytes / originalSizeInMegabytes * 100);
      console.log(`Compressed image type ${selectedFile.type} to ${compressionRatio}% (target was ${targetQuality * 100}) - from ${originalSizeInMegabytes}MB to ${compressedSizeInMegabytes}MB`);
      convertAndReturn(compressedImage, setPhotoDataUrl);
    }
  };

  const convertAndReturn = (file: File | Blob, successCallback: (base64data: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      const base64data = reader.result;
      if (typeof base64data === "string") {
        successCallback(base64data);
      } else {
        throw new Error("Unexpected type for base64data");
      }
    }
  }

  const handleClick = () => {
    if (inputRef === null) throw new Error("inputRef is null");
    if (inputRef.current === null) throw new Error("inputRef.current is null");
    inputRef.current.click();
  }

  return (
    <div>
      <HStack>
        <Button width={"100%"} onClick={handleClick}>
          Upload Photo
        </Button>
      </HStack>
      <input
        hidden={true}
        ref={inputRef}
        accept="image/*,capture=camera"
        type="file"
        onChange={(event) => handleCompressedUpload(event)}
      />
    </div>
  );
};
