import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import { LogoutView } from "../../views/LogoutView";
import { HomeView } from "../../views/HomeView";
import { BaseView } from "../BaseView/BaseView";
import { LoginView } from "../../views/LoginView";
import { DeliveriesView } from "../../views/DeliveriesView";
import { ChakraProvider } from "@chakra-ui/react";
import { FaqsView } from "../../views/FaqsView/FaqsView";
import { Error404View } from "../../views/Error404View";
import { DashboardView } from "../../views/DashboardView/DashboardView";
import { AdminDashboardView } from "../../views/AdminDashboardView";

export function App() {
  return (
    <ChakraProvider>
      <HashRouter>
        <Switch>
          <Route
            exact
            path={RouteConstants.HOME_ROUTE.path}
            render={() => <BaseView viewComponent={<HomeView />} />}
          />
          <Route
            exact
            path={RouteConstants.FAQS_ROUTE.path}
            render={() => <BaseView viewComponent={<FaqsView />} />}
          />
          <Route
            exact
            path={RouteConstants.DELIVERIES_ROUTE.path}
            render={() => <BaseView viewComponent={<DeliveriesView />} />}
          />
          <Route
            exact
            path={RouteConstants.DASHBOARD_ROUTE.path}
            render={() => <BaseView viewComponent={<DashboardView />} />}
          />
          <Route
            exact
            path={RouteConstants.ADMIN_DASHBOARD_ROUTE.path}
            render={() => (
              <BaseView
                viewComponent={<AdminDashboardView />}
                roleNames={["Organisation Admin", "System Admin"]}
              />
            )}
          />
          <Route
            exact
            path={RouteConstants.LOGIN_ROUTE.path}
            render={() => <BaseView viewComponent={<LoginView />} />}
          />
          <Route
            exact
            path={RouteConstants.LOGOUT_ROUTE.path}
            render={() => <BaseView viewComponent={<LogoutView />} />}
          />
          <Route
            path={"*"}
            render={() => <BaseView viewComponent={<Error404View />} />}
          />
        </Switch>
      </HashRouter>
    </ChakraProvider>
  );
}
