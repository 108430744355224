import React, { ReactElement, useState } from "react";
import { Box, Button, Center, Flex, FormControl, FormLabel, Heading, Input, Stack, useToast, } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { setAuthDetails } from "../redux/authSlice";
import { useHistory } from "react-router";
import { RouteConstants } from "../constants/RouteConstants";
import { useLoginMutation } from "../redux/strapiApiSlice";

export const LoginView = (): ReactElement => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [login] = useLoginMutation();
  const { push } = useHistory();
  const toast = useToast();

  return (
    <Flex justify={"center"} mt={"16"}>
      <Stack w={"100%"} maxW={"400px"} spacing={8}>
        <Center>
          <Heading fontSize={"4xl"}>Sign in</Heading>
        </Center>
        <Box rounded={"lg"} boxShadow={"lg"} p={8}>
          <Stack as={"form"} spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                autoFocus
                onChange={(newValue) => setEmail(newValue.target.value)}
                isInvalid={loginErrorMessage !== ""}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(newValue) => setPassword(newValue.target.value)}
                isInvalid={loginErrorMessage !== ""}
              />
            </FormControl>
            <Button
              type={"submit"}
              bg={"blue.400"}
              color={"white"}
              _hover={{
                bg: "blue.500",
              }}
              disabled={isLoggingIn}
              onClick={async () => {
                setIsLoggingIn(true);

                try {
                  const loginSuccessResponse = await login({
                    identifier: email,
                    password,
                  }).unwrap();

                  dispatch(setAuthDetails(loginSuccessResponse));

                  toast({
                    title: `Hi, ${loginSuccessResponse.user.full_name}!`,
                    description: "Sign in successful",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                  });

                  push(RouteConstants.HOME_ROUTE.path);
                } catch (e) {
                  const data = e.data;

                  if (!data) {
                    setIsLoggingIn(false);
                    setLoginErrorMessage("Oops! Server is down, perhaps. Come back later!");
                    return;
                  }

                  const statusCode = data.statusCode;
                  const error = data.error;
                  if (statusCode === 400 && error === "Bad Request") {
                    setIsLoggingIn(false);
                    setLoginErrorMessage("Invalid login credentials.");
                  }
                }
              }}
            >
              {isLoggingIn ? "..." : "Sign in"}
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};
