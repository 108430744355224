import React, { ReactElement } from "react";
import { Text } from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { EDeliveryStatus } from "../types/types";

interface IOwnProps {
  status: EDeliveryStatus;
}

export const StatusSymbol = ({ status }: IOwnProps): ReactElement => {
  let statusContent;
  if (status === EDeliveryStatus.DELIVERED)
    statusContent = <CheckIcon color={"green.500"} />;
  else if (status === EDeliveryStatus.DELIVERY_FAILED)
    statusContent = <CloseIcon color={"red.500"} />;
  else statusContent = <Text color={"gray.500"}>-</Text>;

  return statusContent;
};
