import React, { ReactElement } from "react";
import {
  CircularProgress,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

interface IOwnProps {
  text: string;
}

export const AppCircularProgress = (props: IOwnProps): ReactElement => {
  return (
    <Stack alignItems={"center"}>
      <CircularProgress
        isIndeterminate
        color={useColorModeValue("red.500", "gray.900")}
      />
      <Text>{props.text}</Text>
    </Stack>
  );
};
