import React, { ReactElement } from "react";
import { clearAuthDetails, selectJwt, selectUser } from "../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Redirect } from "react-router";
import { RouteConstants } from "../constants/RouteConstants";
import { AppCircularProgress } from "../components/AppCircularProgress";
import { strapiApi } from "../redux/strapiApiSlice";

export const LogoutView = (): ReactElement => {
  const dispatch = useAppDispatch();
  const jwt = useAppSelector(selectJwt);
  const user = useAppSelector(selectUser);
  const isLoggedIn = jwt && user;

  if (isLoggedIn) {
    setTimeout(() => {
      dispatch(strapiApi.util.resetApiState());
      dispatch(clearAuthDetails());
    }, 1000);
    return <AppCircularProgress text={"Signing out..."} />;
  }

  return <Redirect to={RouteConstants.LOGIN_ROUTE.path} />;
};
