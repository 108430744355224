import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { clearError, selectError } from "../redux/errorSlice";

export const ErrorToast = () => {
  const toast = useToast();
  const error = useAppSelector(selectError);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (error) {
      toast({
        status: "error",
        title: error.title,
        description: error.message,
        duration: 5000,
      });
      dispatch(clearError());
    }
  });

  if (error) return <div></div>;
  else return null;
};
