import React, { ReactElement } from "react";
import { Text } from "@chakra-ui/react";

interface IOwnProps {
  text: string;
  limit: number;
}

export const TruncatedText = ({ text, limit }: IOwnProps): ReactElement => {
  const textToDisplay =
    text.length > limit ? `${text.substring(0, limit - 3)}...` : text;

  return <Text>{textToDisplay}</Text>;
};
