import { merge } from "object-mapper";
import { IUser } from "../types/types";
import { IModelMapper } from "./IModelMapper";
import { IUserResponse } from "../types/responses";

export class UserModelMapper implements IModelMapper<IUserResponse, IUser> {
  private map = {
    id: "id",
    email: "email",
    username: "username",
    provider: "provider",
    password: "password",
    resetPasswordToken: "resetPasswordToken",
    confirmationToken: "confirmationToken",
    confirmed: "confirmed",
    blocked: "blocked",
    role: "role",
    mobile_number: "mobileNumber",
    full_name: "fullName",
    created_by: "createdBy",
    updated_by: "updatedBy",
    created_at: "createdAt",
    updated_at: "updatedAt",
  };

  toModel(json: IUserResponse): IUser {
    return merge(json, this.map) as IUser;
  }
}
