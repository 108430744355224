import React, { ReactElement } from "react";
import { Center, Heading, Stack, Text } from "@chakra-ui/react";

export const Error404View = (): ReactElement => {
  return (
    <Center>
      <Stack>
        <Heading textAlign={"center"}>404 Not Found</Heading>
        <Text textAlign={"center"}>
          This page does not exist. Navigate back to known pastures, friend.
        </Text>
      </Stack>
    </Center>
  );
};
