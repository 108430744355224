import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { IBeneficiary, IDelivery } from "../types/types";
import { AddressWithLink } from "./AddressWithLink";
import { ExternalLink } from "./ExternalLink";
import { StatusSymbol } from "./StatusSymbol";

interface IOwnProps {
  deliveries: IDelivery[];
}

export const DeliveriesAsTable = ({ deliveries }: IOwnProps): ReactElement => {
  const content = deliveries.map((delivery) => {
    const { status, beneficiary } = delivery;

    const {
      uniqueIdentifier,
      blockNumber,
      streetName,
      unitNumber,
      mobileNumber,
      googleMapsLink,
    } = beneficiary as IBeneficiary;

    return (
      <Tr key={uniqueIdentifier}>
        <Td>
          <AddressWithLink
            blockNumber={blockNumber}
            streetName={streetName}
            unitNumber={unitNumber}
            googleMapsLink={googleMapsLink}
          />
        </Td>
        <Td>
          <ExternalLink text={mobileNumber} href={`tel:${mobileNumber}`} />
        </Td>
        <Td textAlign={"center"}>
          <StatusSymbol status={status} />
        </Td>
      </Tr>
    );
  });

  return (
    <Box mb={8} boxShadow={"md"} rounded={"md"} w={"full"}>
      <Table size="sm" variant={"simple"}>
        <Thead>
          <Tr>
            <Th color={"gray.500"} fontSize={"xx-small"}>Address</Th>
            <Th color={"gray.500"} fontSize={"xx-small"}>Phone</Th>
            <Th color={"gray.500"} fontSize={"xx-small"}>Status</Th>
          </Tr>
        </Thead>

        <Tbody>{content}</Tbody>
      </Table>
    </Box>
  );
};
