import { ReactElement } from "react";
import { Link, Stack, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

interface IOwnProps {
  text: string;
  href: string;
}

export const ExternalLink = (props: IOwnProps): ReactElement => {
  const { text, href } = props;
  return (
    <Stack display={"inline-block"} direction={"row"} alignItems={"center"}>
      <Text as={"span"}>{text}</Text>
      <Link isExternal href={href} color={"#1a0dab"}>
        <ExternalLinkIcon />
      </Link>
    </Stack>
  );
};
