import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { ReactElement, useEffect, useState } from "react";
import { EDeliveryStatus, IBeneficiary, IDelivery } from "../types/types";
import { useUpdateDeliveryMutation } from "../redux/strapiApiSlice";
import { AddressWithLink } from "./AddressWithLink";
import { ExternalLink } from "./ExternalLink";
import { DeliveryPhotoModal } from "./DeliveryPhotoModal";
import { PhotoUpload } from "./PhotoUpload";

interface IOwnProps {
  delivery: IDelivery;
}

export const DeliveryCard = ({ delivery }: IOwnProps): ReactElement => {
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [status, setStatus] = useState(delivery.status);
  const [photoDataUrl, setPhotoDataUrl] = useState("");
  const [driverRemarks, setDriverRemarks]: [string, any] = useState(
    delivery.driverRemarks ?? ""
  );
  const [updateDelivery] = useUpdateDeliveryMutation();
  const toast = useToast();

  useEffect(() => shouldEnableSaveButton());

  const shouldEnableSaveButton = () => {
    const hasIsDeliveredChanged = status !== delivery.status;

    const originalDriverRemarks = delivery.driverRemarks ?? "";
    const hasDriverRemarksChanged = driverRemarks !== originalDriverRemarks;

    const hasPhotoBeenTaken = photoDataUrl !== "";

    const result =
      hasIsDeliveredChanged || hasDriverRemarksChanged || hasPhotoBeenTaken;
    setEnableSaveButton(result);
  };

  const {
    uniqueIdentifier,
    recipientName,
    mobileNumber,
    blockNumber,
    unitNumber,
    streetName,
    postalCode,
    googleMapsLink,
  } = delivery.beneficiary as IBeneficiary;

  const { caseNotes } = delivery;

  return (
    <Box mb={8} boxShadow={"md"} rounded={"md"} w={"full"}>
      <Stack spacing={4} p={4}>
        <Stack spacing={"0"} mt={"4"}>
          <Text color={"gray.500"} fontSize={"small"}>
            Beneficiary ID
          </Text>
          <Text>{uniqueIdentifier}</Text>
        </Stack>

        <Stack spacing={"0"} mt={"4"}>
          <Text color={"gray.500"} fontSize={"small"}>
            Address
          </Text>
          <AddressWithLink
            blockNumber={blockNumber}
            streetName={streetName}
            unitNumber={unitNumber}
            postalCode={postalCode}
            googleMapsLink={googleMapsLink}
          />
        </Stack>

        <Stack spacing={"0"} mt={"4"}>
          <Text color={"gray.500"} fontSize={"small"}>
            Beneficiary
          </Text>
          <Text>{recipientName}</Text>
        </Stack>

        <Stack spacing={"0"} mt={"4"}>
          <Text color={"gray.500"} fontSize={"small"}>
            Contact Number
          </Text>
          <ExternalLink text={mobileNumber} href={`tel:${mobileNumber}`} />
        </Stack>

        {caseNotes && (
          <Stack spacing={"0"} mt={"4"}>
            <Text color={"gray.500"} fontSize={"small"}>
              Case Notes
            </Text>
            <Text>{caseNotes}</Text>
          </Stack>
        )}
      </Stack>

      <Divider />

      <Stack spacing={4} p={4}>
        <Stack>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="toggleDelivered" mb="0">
              Status
            </FormLabel>
          </FormControl>
          <Select
            value={status}
            onChange={(event) =>
              setStatus(
                EDeliveryStatus[
                  event.target.value as keyof typeof EDeliveryStatus
                ]
              )
            }
          >
            <option value={EDeliveryStatus.NOT_STARTED.toString()}>
              Not Started
            </option>
            <option value={EDeliveryStatus.DELIVERED.toString()}>
              Delivered
            </option>
            <option value={EDeliveryStatus.DELIVERY_FAILED.toString()}>
              Delivery Failed
            </option>
          </Select>
        </Stack>

        <Stack>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="remarks" mb="0">
              Remarks
            </FormLabel>
          </FormControl>
          <Stack direction={"row"} alignItems={"center"}>
            <Textarea
              placeholder={"E.g. Nobody answered door."}
              value={driverRemarks ?? ""}
              onChange={(event) => setDriverRemarks(event.target.value)}
            />
          </Stack>
        </Stack>

        <Stack>
          <FormLabel htmlFor="remarks" mb="0">
            Photo
          </FormLabel>
          {delivery.deliveryPhoto ? (
            <DeliveryPhotoModal delivery={delivery} />
          ) : null}
          <PhotoUpload
            photoDataUrl={photoDataUrl}
            setPhotoDataUrl={setPhotoDataUrl}
          />
        </Stack>

        <Button
          bg={"blue.400"}
          color={"white"}
          _hover={{
            bg: "blue.500",
          }}
          disabled={!enableSaveButton}
          onClick={async () => {
            try {
              await updateDelivery({
                id: delivery.id,
                status,
                driverRemarks,
                photoDataUrl,
              });
              setPhotoDataUrl("");
              toast({
                status: "success",
                title: "Update successful",
                duration: 5000,
              });
            } catch (e) {
              toast({
                status: "error",
                title: "Update failed",
                description: e.data.message,
                duration: 5000,
              });
            }
          }}
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
};
