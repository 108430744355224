import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { clearAuthDetails } from "../redux/authSlice";
import { store } from "../redux/store";

function onRequestSuccess(config: AxiosRequestConfig) {
  if (!config.headers.authorization) {
    config.headers = {
      Authorization: `Bearer ${store.getState().authReducer.jwt}`
    }
  }
  return config;
}

function onRequestFail(error: AxiosError) {
  return Promise.reject(error);
}

function onResponseSuccess(response: AxiosResponse) {
  return response;
}

function onResponseFail(error: AxiosError) {
  store.dispatch(clearAuthDetails());
  return Promise.reject(error);
}

export function setupAxiosInterceptors() {
  axios.interceptors.request.use(onRequestSuccess, onRequestFail);
  axios.interceptors.response.use(onResponseSuccess, onResponseFail);
}
