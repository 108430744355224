import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { IDelivery } from "../types/types";
import { DeliveryPhoto } from "./DeliveryPhoto";

interface IOwnProps {
  delivery: IDelivery;
}

export const DeliveryPhotoModal = ({ delivery }: IOwnProps): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen}>
        {isOpen ? "Hide Delivery Photo" : "Show Delivery Photo"}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delivery Photo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DeliveryPhoto deliveryPhoto={delivery.deliveryPhoto} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
