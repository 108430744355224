import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface IError {
  title: string;
  message: string;
}

interface IInitialState {
  error?: IError;
}

const initialState = { error: undefined } as IInitialState;

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError(state, action: PayloadAction<IError>) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = undefined;
    },
  },
});

export const { setError, clearError } = errorSlice.actions;
export const selectError = (state: RootState) => state.errorReducer.error;
export const errorReducer = errorSlice.reducer;
