import { EDeliveryStatus, IDelivery } from "../types/types";
import { IModelMapper } from "./IModelMapper";
import { IDeliveryResponse } from "../types/responses";
import { BeneficiaryModelMapper } from "./BeneficiaryModelMapper";
import { EventModelMapper } from "./EventModelMapper";
import { UserModelMapper } from "./UserModelMapper";
import { DeliveryPhotoModelMapper } from "./DeliveryPhotoModelMapper";

export class DeliveryModelMapper
  implements IModelMapper<IDeliveryResponse, IDelivery>
{
  toModel(json: IDeliveryResponse): IDelivery {
    return {
      id: json.id,
      beneficiary: new BeneficiaryModelMapper().toModel(json.beneficiary),
      event: new EventModelMapper().toModel(json.event),
      driver: new UserModelMapper().toModel(json.driver),
      caseNotes: json.case_notes,
      driverRemarks: json.driver_remarks,
      status: EDeliveryStatus[(json.status) as keyof typeof EDeliveryStatus],
      deliveryPhoto: new DeliveryPhotoModelMapper().toModel(json.delivery_photo),
    };
  }
}
