import Compressor from "compressorjs";

export class ImageCompressor {
  private readonly targetSizeInMegabytes: number;

  constructor(targetSizeInMegabytes: number) {
    this.targetSizeInMegabytes = targetSizeInMegabytes;
  }

  async compress(selectedFile: File) {
    const originalSizeInMegabytes = selectedFile.size / 1000000;
    const targetQuality = this.targetSizeInMegabytes / originalSizeInMegabytes;

    if (targetQuality >= 1) {
      return selectedFile;
    } else {
      return new Promise<File | Blob>((resolve, reject) => {
        new Compressor(selectedFile, {
          quality: targetQuality,
          convertTypes: ['image/jpeg', 'image/png', 'image/webp'],
          success: resolve,
          error: reject
        })
      });
    }
  }
}
