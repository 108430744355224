import { Image, Stack } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import "react-html5-camera-photo/build/css/index.css";
import { UploadPhoto } from "./UploadPhoto";

interface IOwnProps {
  photoDataUrl: string;
  setPhotoDataUrl: (photoDataUrl: string) => void;
}

export const PhotoUpload = ({
  photoDataUrl,
  setPhotoDataUrl,
}: IOwnProps): ReactElement => {
  return (
    <Stack>
      <UploadPhoto setPhotoDataUrl={setPhotoDataUrl} />
      {photoDataUrl !== "" ? <Image src={photoDataUrl} alt={"Preview"} /> : null}
    </Stack>
  );
};
