import { Box, Stat, StatGroup, StatLabel, StatNumber } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { EDeliveryStatus, IDelivery } from "../types/types";

interface IOwnProps {
  deliveries: IDelivery[];
  onStatisticClick: (status: EDeliveryStatus | "ALL") => void;
}

export const DeliveryStatistics = ({
  deliveries,
  onStatisticClick,
}: IOwnProps): ReactElement => {
  let deliveriesNotStarted = 0;
  let deliveriesDelivered = 0;
  let deliveriesFailed = 0;
  deliveries.forEach((delivery) => {
    if (delivery.status === EDeliveryStatus.NOT_STARTED.toString())
      deliveriesNotStarted++;
    else if (delivery.status === EDeliveryStatus.DELIVERED.toString())
      deliveriesDelivered++;
    else if (delivery.status === EDeliveryStatus.DELIVERY_FAILED.toString())
      deliveriesFailed++;
    else throw new Error(`Unknown status: ${delivery.status}`);
  });

  return (
    <Box>
      <StatGroup justifyContent={"flex-start"}>
        <Stat maxW={"fit-content"}>
          <StatLabel>Total Deliveries</StatLabel>
          <StatNumber
            color={"gray.500"}
            onClick={() => onStatisticClick("ALL")}
          >
            {deliveries.length}
          </StatNumber>
        </Stat>
      </StatGroup>

      <StatGroup>
        <Stat>
          <StatLabel>Not Started</StatLabel>
          <StatNumber
            color={"gray.500"}
            onClick={() => onStatisticClick(EDeliveryStatus.NOT_STARTED)}
          >
            {deliveriesNotStarted}
          </StatNumber>
        </Stat>

        <Stat>
          <StatLabel>Delivered</StatLabel>
          <StatNumber
            color={"green.500"}
            onClick={() => onStatisticClick(EDeliveryStatus.DELIVERED)}
          >
            {deliveriesDelivered}
          </StatNumber>
        </Stat>

        <Stat>
          <StatLabel>Failed</StatLabel>
          <StatNumber
            color={"red.500"}
            onClick={() => onStatisticClick(EDeliveryStatus.DELIVERY_FAILED)}
          >
            {deliveriesFailed}
          </StatNumber>
        </Stat>
      </StatGroup>
    </Box>
  );
};
