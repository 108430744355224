import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import { authReducer } from "./authSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { strapiApi } from "./strapiApiSlice";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { authMiddleware } from "./middleware/authMiddleware";
import { errorReducer } from "./errorSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [strapiApi.reducerPath],
};

const reducers = combineReducers({
  authReducer,
  errorReducer,
  [strapiApi.reducerPath]: strapiApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(strapiApi.middleware, authMiddleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
