import { ReactElement, useCallback, useEffect, useState } from "react";
import { EDeliveryStatus, IEvent } from "../../types/types";
import { Divider, FormControl, FormLabel, Heading, Select, Stack, Text } from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import { DeliveryStatistics } from "../DeliveryStatistics";
import { RedactedDeliveriesAsTable } from "../RedactedDeliveriesAsTable";
import { useGetRedactedDeliveriesQuery } from "../../redux/strapiApiSlice";
import { AppCircularProgress } from "../AppCircularProgress";
import styles from "./DeliveriesDashboard.module.scss";

interface IOwnProps {
  selectedEvent: IEvent;
}

export const DeliveriesDashboard = (props: IOwnProps): ReactElement => {
  const { selectedEvent } = props;
  const {
    data: deliveries,
    isSuccess,
    isFetching,
  } = useGetRedactedDeliveriesQuery({ eventId: selectedEvent.id }, { refetchOnFocus: true });
  const [filteredDeliveries, setFilteredDeliveries] = useState(deliveries);
  const [statusFilter, setStatusFilter] = useState(
    EDeliveryStatus.NOT_STARTED.toString()
  );

  const filterByStatus = useCallback(() => {
    if (deliveries === undefined) return;
    if (statusFilter === "ALL") {
      setFilteredDeliveries(deliveries);
      return;
    }
    setFilteredDeliveries(
      deliveries.filter((delivery) => delivery.status === statusFilter)
    );
  }, [deliveries, statusFilter]);

  useEffect(() => {
    if (deliveries !== undefined) {
      if (statusFilter) {
        filterByStatus();
      } else {
        setFilteredDeliveries(deliveries);
      }
    }
  }, [deliveries, statusFilter, filterByStatus]);

  if (!deliveries) {
    return <AppCircularProgress text={"Loading..."} />;
  }

  if (isSuccess && deliveries.length === 0) {
    return <Text>No deliveries found.</Text>;
  }

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} spacing={"auto"}>
        <Heading fontSize={"xl"}>Dashboard</Heading>
        {isFetching && <RepeatIcon className={styles.rotate} />}
      </Stack>
      <DeliveryStatistics
        deliveries={deliveries}
        onStatisticClick={(status) => setStatusFilter(status)}
      />
      <Divider />
      <Stack>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="toggleDelivered" mb="0">
            Status
          </FormLabel>
        </FormControl>
        <Select
          value={statusFilter}
          onChange={(event) => {
            setStatusFilter(
              EDeliveryStatus[
                event.target.value as keyof typeof EDeliveryStatus
                ]
            );
            filterByStatus();
          }}
        >
          <option value={"ALL"}>All</option>
          <option value={EDeliveryStatus.NOT_STARTED.toString()}>
            Not Started
          </option>
          <option value={EDeliveryStatus.DELIVERED.toString()}>
            Delivered
          </option>
          <option value={EDeliveryStatus.DELIVERY_FAILED.toString()}>
            Delivery Failed
          </option>
        </Select>
      </Stack>
      {filteredDeliveries && (
        <RedactedDeliveriesAsTable deliveries={filteredDeliveries} />
      )}
    </>
  )
};
