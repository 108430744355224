import React, { ReactElement, useState } from "react";
import { Center, Heading, Image, Stack } from "@chakra-ui/react";
import { FaqItem } from "../../components/FaqItem";
import faqsJamiyah from "./FaqsData-no-meat.jamiyah.json";
import faqsMtfa from "./FaqsData-no-meat.mtfa.json";
import logoJamiyah from "../../assets/jamiyah_food_bank.jpeg";
import { IEvent } from "../../types/types";
import { EventDropdownMenu } from "../../components/EventDropdownMenu";

export const FaqsView = (): ReactElement => {
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);

  const logoToUse = getLogo(selectedEvent);
  const faqsToUse = getFaqs(selectedEvent);

  return (
    <Stack spacing={8} mb={8}>

      <EventDropdownMenu selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent} />

      {logoToUse && (
        <Center>
          <Image src={logoToUse} w={"100%"} maxW={"300px"} />
        </Center>
      )}

      {selectedEvent && <Heading fontSize={"xl"}>FAQs</Heading>}

      {faqsToUse !== null && faqsToUse.map((faqJson) => (
        <FaqItem
          serialNumber={faqJson.serialNumber}
          questionText={faqJson.questionText}
          textLines={faqJson.textLines}
          key={faqJson.serialNumber}
        />
      ))}
    </Stack>
  );
};

function getFaqs(selectedEvent: IEvent | null) {
  if (selectedEvent === null) {
    return null;
  }

  switch (selectedEvent.organisation.name) {
    case "Jamiyah Food Bank":
      return faqsJamiyah;
    case "Muslimin Trust Fund Association":
      return faqsMtfa;
    default:
      throw new Error(`No FAQs found for event: ${selectedEvent.title}`);
  }
}

function getLogo(selectedEvent: IEvent | null) {
  if (selectedEvent === null) {
    return null;
  }

  switch (selectedEvent.organisation.name) {
    case "Jamiyah Food Bank":
      return logoJamiyah;
    case "Muslimin Trust Fund Association":
      return null;
    default:
      throw new Error(`No logo found for event: ${selectedEvent.title}`);
  }
}
