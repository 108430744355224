import { merge } from "object-mapper";
import { IBeneficiary } from "../types/types";
import { IModelMapper } from "./IModelMapper";
import { IBeneficiaryResponse } from "../types/responses";

export class BeneficiaryModelMapper
  implements IModelMapper<IBeneficiaryResponse, IBeneficiary>
{
  private map = {
    id: "id",
    unique_identifier: "uniqueIdentifier",
    recipient_name: "recipientName",
    mobile_number: "mobileNumber",
    block_number: "blockNumber",
    unit_number: "unitNumber",
    street_name: "streetName",
    area: "area",
    postal_code: "postalCode",
    lat: "lat",
    lng: "lng",
    google_maps_link: "googleMapsLink",
    created_by: "createdBy",
    updated_by: "updatedBy",
    created_at: "createdAt",
    updated_at: "updatedAt",
  };

  toModel(json: IBeneficiaryResponse): IBeneficiary {
    return merge(json, this.map) as IBeneficiary;
  }
}
