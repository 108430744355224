import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "./store";
import { IDelivery, IEvent } from "../types/types";
import { ApiConstants } from "../constants/ApiConstants";
import { IDeliveryResponse, IEventResponse, ILoginSuccessResponse } from "../types/responses";
import { DeliveryModelMapper } from "../modelMappers/DeliveryModelMapper";
import { ILoginRequestBody } from "../types/requests";
import { EventModelMapper } from "../modelMappers/EventModelMapper";

interface IUpdateDeliveryQueryArg {
  id: number;
  status: string;
  driverRemarks: string;
  photoDataUrl: string;
}

export const strapiApi = createApi({
  reducerPath: "strapiApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiConstants.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      if (headers.has(ApiConstants.AUTHENTICATION_HEADER)) {
        headers.delete(ApiConstants.AUTHENTICATION_HEADER);
        return headers;
      }
      const jwt = (getState() as RootState).authReducer.jwt;
      if (jwt) {
        headers.set("Authorization", `Bearer ${jwt}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Delivery", "Redacted Delivery", "EventMine"],
  endpoints: (builder) => ({
    getEventsMine: builder.query<IEvent[], null>({
      query: () => `/events/mine`,
      transformResponse: (response: IEventResponse[]) =>
        response.map((eventResponse) =>
          new EventModelMapper().toModel(eventResponse)
        ),
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({ type: "EventMine" as const, id })),
              { type: "EventMine", id: "LIST" },
            ]
          : [{ type: "EventMine", id: "LIST" }];
      },
    }),
    getDeliveries: builder.query<IDelivery[], { eventId: number }>({
      query: (request) => `/deliveries?eventId=${request.eventId}`,
      transformResponse: (response: IDeliveryResponse[]) =>
        response.map((deliveryResponse) =>
          new DeliveryModelMapper().toModel(deliveryResponse)
        ),
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({ type: "Delivery" as const, id })),
              { type: "Delivery", id: "LIST" },
            ]
          : [{ type: "Delivery", id: "LIST" }];
      },
    }),
    getRedactedDeliveries: builder.query<IDelivery[], { eventId: number }>({
      query: (request) => `/redacted/deliveries?eventId=${request.eventId}`,
      transformResponse: (response: IDeliveryResponse[]) => {
        return response.map((deliveryResponse) =>
          new DeliveryModelMapper().toModel(deliveryResponse)
        );
      },
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: "Redacted Delivery" as const,
                id,
              })),
              { type: "Redacted Delivery", id: "LIST" },
            ]
          : [{ type: "Redacted Delivery", id: "LIST" }];
      },
    }),
    getDeliveryPhoto: builder.query<string, { id: number }>({
      query: (request) => ({
        url: `/delivery-photos/photo/${request.id}`,
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response) => URL.createObjectURL(response),
    }),
    updateDelivery: builder.mutation<
      IDeliveryResponse,
      IUpdateDeliveryQueryArg
    >({
      query: ({ id, photoDataUrl, status, driverRemarks }) => {
        const body = new FormData();
        body.append("id", id.toString());
        body.append("status", status);
        body.append("driver_remarks", driverRemarks);

        if (photoDataUrl) {
          const array = photoDataUrl.split(",");
          const mime = array[0].match(/:(.*?);/)!![1];
          const acceptedMimes = ["image/jpeg", "image/png"];
          if (acceptedMimes.includes(mime)) {
            const binaryString = atob(array[1]);
            let n = binaryString.length;
            const uint8Array = new Uint8Array(n);
            while (n--) {
              uint8Array[n] = binaryString.charCodeAt(n);
            }
            const photoFile = new File(
              [uint8Array],
              `delivery-photo.${mime.split("/")[1]}`,
              { type: mime }
            );
            body.append("photo", photoFile);
          }
        }

        return {
          url: `deliveries/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Delivery", id: arg.id },
        { type: "Redacted Delivery", id: arg.id },
      ],
    }),
    login: builder.mutation<ILoginSuccessResponse, ILoginRequestBody>({
      query: (requestBody) => ({
        url: `auth/local`,
        method: "POST",
        body: requestBody,
        headers: {
          [ApiConstants.AUTHENTICATION_HEADER]: "true", // To signal prepareHeaders to not add the Auth Bearer header.
        },
      }),
    }),
  }),
});

export const {
  useGetEventsMineQuery,
  useGetDeliveriesQuery,
  useGetRedactedDeliveriesQuery,
  useGetDeliveryPhotoQuery,
  useUpdateDeliveryMutation,
  useLoginMutation,
} = strapiApi;
