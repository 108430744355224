import { isRejected, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { setError } from "../errorSlice";
import { clearAuthDetails } from "../authSlice";

export const authMiddleware: Middleware = (api) => (next) => (action) => {
  // console.log(api.getState());

  if (isRejected(action)) {
    if (!action.payload) {
      console.log(`This action has no payload: ${action}`);
      return next(action);
    }

    if (action.payload.status === "FETCH_ERROR") {
      console.log(
        "Unable to fetch. Server down, perhaps? Clearing auth details..."
      );
      api.dispatch(
        setError({
          title: "Oops!",
          message: `Server's down. Try again later! ${JSON.stringify(action.payload)}`,
        })
      );
      // api.dispatch(clearAuthDetails());
    }

    if (action.payload.data) {
      handleErrorWithData(api, action);
    }
  }

  return next(action);
};

function handleErrorWithData(api: MiddlewareAPI, action: any) {
  if (action.payload.data.statusCode === 401) {
    console.log("Got a 401 Unauthorized response. Clearing auth details...");
    api.dispatch(
      setError({
        title: "Oops!",
        message: "Login expired. Please sign in again.",
      })
    );
    api.dispatch(clearAuthDetails());
  }

  if (action.payload.data.statusCode === 403) {
    console.log("Got a 403 Forbidden response. Clearing auth details...");
    api.dispatch(
      setError({
        title: "Oops!",
        message: "Oops! You're not allowed there.",
      })
    );
    api.dispatch(clearAuthDetails());
  }

  if (action.payload.data.statusCode === 400) {
    if (Array.isArray(action.payload.data.message)) {
      const firstErrorMessageId = action.payload.data.message[0].messages[0].id;
      if (firstErrorMessageId === "Auth.form.error.invalid") {
        api.dispatch(
          setError({
            title: "Oops!",
            message: "Invalid login credentials",
          })
        );
      }
    }
  }
}
